import React, { useEffect, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { Space, Table, Tag } from "antd";
import { BsThreeDotsVertical } from "react-icons/bs";
import axios from "axios";
import { switchEndpoint } from "../../../../../../redux/actions/endpoints";
import { toast } from "react-toastify";

const Collectionn = ({ setTitleName }) => {
  const [collData, setCollData] = useState([]);
  const columns = [
    {
      title: "SN",
      dataIndex: "key",
      key: "id",
      sorter: (a, b) => a.id - b.id,
      render: (id, record, index) => {
        ++index;
        return index;
      },
    },
    // {
    //   title: "Location",
    //   dataIndex: "address",
    //   key: "location",
    // },
    {
      title: "Center Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Weight",
      dataIndex: "weight",
      key: "weight",
    },
    {
      title: "Phone Number",
      dataIndex: "phone",
      key: "phoneNumber",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },

    {
      title: " ",
      key: "action",
      render: () => <BsThreeDotsVertical style={{ cursor: "pointer" }} />,
    },
  ];

  const data = [
    {
      key: "1",
      weight: "54.49kg",
      location: "13 Alhaji Masha Road, Surulere",
      phoneNumber: "090 5942 2453",
      code: "922 419",
      status: (
        <div
          style={{
            padding: "5px 5px",
            backgroundColor: "#C4DA00",
            color: "#000",
            borderRadius: "50px",
            textAlign: "center",
            width: "90px",
          }}
        >
          <p className="mb-0">Active</p>
        </div>
      ),
    },
    {
      key: "2",
      weight: "54.49kg",
      location: "13 Alhaji Masha Road, Surulere",
      phoneNumber: "090 5942 2453",
      code: "922 419",
      status: (
        <div
          style={{
            padding: "5px 5px",
            backgroundColor: "#C4DA00",
            color: "#000",
            borderRadius: "50px",
            textAlign: "center",
            width: "90px",
          }}
        >
          <p className="mb-0">Inactive</p>
        </div>
      ),
    },
    {
      key: "3",
      weight: "54.39kg",
      location: "13 Alhaji Masha Road, Surulere",
      phoneNumber: "090 5942 2453",
      code: "922 419",
      status: (
        <div
          style={{
            padding: "5px 5px",
            backgroundColor: "#C4DA00",
            color: "#000",
            borderRadius: "50px",
            textAlign: "center",
            width: "90px",
          }}
        >
          <p className="mb-0">Active</p>
        </div>
      ),
    },
    {
      key: "4",
      weight: "54.49kg",
      location: "13 Alhaji Masha Road, Surulere",
      phoneNumber: "090 5942 2453",
      code: "922 419",
      status: (
        <div
          style={{
            padding: "5px 5px",
            backgroundColor: "#C4DA00",
            color: "#000",
            borderRadius: "50px",
            textAlign: "center",
            width: "90px",
          }}
        >
          <p className="mb-0">Inactive</p>
        </div>
      ),
    },
    {
      key: "5",
      weight: "54.39kg",
      location: "13 Alhaji Masha Road, Surulere",
      phoneNumber: "090 5942 2453",
      code: "922 419",
      status: (
        <div
          style={{
            padding: "5px 5px",
            backgroundColor: "#C4DA00",
            color: "#000",
            borderRadius: "50px",
            textAlign: "center",
            width: "90px",
          }}
        >
          <p className="mb-0">Active</p>
        </div>
      ),
    },
    {
      key: "6",
      weight: "54.49kg",
      location: "13 Alhaji Masha Road, Surulere",
      phoneNumber: "090 5942 2453",
      code: "922 419",
      status: (
        <div
          style={{
            padding: "5px 5px",
            backgroundColor: "#C4DA00",
            color: "#000",
            borderRadius: "50px",
            textAlign: "center",
            width: "90px",
          }}
        >
          <p className="mb-0">Inactive</p>
        </div>
      ),
    },
    {
      key: "7",
      weight: "54.39kg",
      location: "13 Alhaji Masha Road, Surulere",
      phoneNumber: "090 5942 2453",
      code: "922 419",
      status: (
        <div
          style={{
            padding: "5px 5px",
            backgroundColor: "#C4DA00",
            color: "#000",
            borderRadius: "50px",
            textAlign: "center",
            width: "90px",
          }}
        >
          <p className="mb-0">Active</p>
        </div>
      ),
    },
    {
      key: "8",
      weight: "54.49kg",
      location: "13 Alhaji Masha Road, Surulere",
      phoneNumber: "090 5942 2453",
      code: "922 419",
      status: (
        <div
          style={{
            padding: "5px 5px",
            backgroundColor: "#C4DA00",
            color: "#000",
            borderRadius: "50px",
            textAlign: "center",
            width: "90px",
          }}
        >
          <p className="mb-0">Inactive</p>
        </div>
      ),
    },
    {
      key: "9",
      weight: "54.39kg",
      location: "13 Alhaji Masha Road, Surulere",
      phoneNumber: "090 5942 2453",
      code: "922 419",
      status: (
        <div
          style={{
            padding: "5px 5px",
            backgroundColor: "#C4DA00",
            color: "#000",
            borderRadius: "50px",
            textAlign: "center",
            width: "90px",
          }}
        >
          <p className="mb-0">Active</p>
        </div>
      ),
    },
  ];

  const getCollection = () => {
    const token = localStorage.getItem("token");
    axios
      .get(
        `${switchEndpoint}/aggregation-center/collection-center-dropoffs/centers-list`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        // console.log(response.data);
        setCollData(response.data);
        const result = response.data;
      })
      .catch((error) => {
        toast.error(error.response, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          pauseOnHover: true,
          progress: undefined,
          theme: "light",
        });
      });
  };

  useEffect(() => {
    setTitleName("Manage User");
    getCollection();
  }, []);
  return (
    <main>
      <div className="main__container">
        <div className="cardss p-0 py-1">
          <span className="d-flex align-items-center py-3 px-2">
            <b>Collection Center </b>({collData.length})
          </span>
          <Table columns={columns} dataSource={collData} size="middle" />
        </div>
      </div>
    </main>
  );
};

export default Collectionn;
